import React from 'react';
import ReactDOMServer from "react-dom/server";
import { Link, } from "react-router-dom";

import axios from 'axios';
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/tr';
import NumberFormat from 'react-number-format';

export default class SingleTweet extends React.Component {


    constructor(props) {

        super(props);
        this.state = {
            tweet: [],
        };

    }



    getTweet = (id) => {



        axios.get(`http://api.peypi.com/api/single/${id}`)
            .then(res => {
                const tweet = res.data;
                const tweetid = tweet.tweet_id;
                this.setState({ tweet: tweet });
            });
    };


    componentDidMount() {

        const id = this.props.match.params.id;

        this.getTweet(id);

    }

    render() {


        return (


            <div className="row">
           <div className="col-sm-8">

            <div className="tweet">
            <Helmet>
                <title>{ `${ this.state.tweet.text }` } - @{ `${this.state.tweet.username}`}   | Sosyal Gündem</title>


            </Helmet>
          <p className="tweettext">    {(this.state.tweet.text)} </p>

          {this.state.tweet.image_url &&
          <p className="tweettext">  <img src={(this.state.tweet.image_url)} className="img-fluid" alt="{(this.state.tweet.text)}" /> </p>

              }
                {this.state.tweet.image_url2 &&
                <p className="tweettext">  <img src={(this.state.tweet.image_url2)} className="img-fluid" /> </p>

        }
        {this.state.tweet.image_url3 &&
                <p className="tweettext">  <img src={(this.state.tweet.image_url3)} className="img-fluid" /> </p>

        }
         {this.state.tweet.image_url4 &&
                <p className="tweettext">  <img src={(this.state.tweet.image_url4)} className="img-fluid" /> </p>

        }
               {this.state.tweet.video_url &&
                      <p className="tweettext">  <ReactPlayer className='react-player' url={(this.state.tweet.video_url)} controls="true"  width='100%'  /> </p>

              }
{this.state.tweet.profile &&

                    <p className="tweetbottom">  <img src={(this.state.tweet.profile.profile_image_url)} className="img-fluid" /> <Link to={`/detay/${this.state.tweet.id}`}><Moment fromNow>{(this.state.tweet.tweet_date)}</Moment></Link> -  <Link to={`/profil/${this.state.tweet.username}`}>{(this.state.tweet.profile.name)}</Link> - <NumberFormat value={this.state.tweet.pp} displayType={'text'} thousandSeparator={true}  /> PP -  <a href={`https://twitter.com/${this.state.tweet.username}/status/${this.state.tweet.tweet_id}`} target="_BLANK">@twitter</a> </p> 

            }


        

          </div>
</div>
           <div className="col-sm-4">
</div>
</div>
        )
    }
}