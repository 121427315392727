import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';


import LastTweet from './LastTweet'
import LastDay from './LastDay'
import LastHour from './LastHour'
import Header from './Header'
import AllDay from './AllDay'
import LastWeek from './LastWeek'

import SingleTweet from './SingleTweet'
import UserTweets from './UserTweets'
import { Helmet } from "react-helmet";


export default function App() {
    return (

        <BrowserRouter>
      <Helmet titleTemplate="%s - Peypi" defaultTitle="Peypi - Sosyal Gündem">
      <meta name="description" content="%s - Peypi - Türkiyenin Sosyal Gündemi" />
    </Helmet>
         <Header />

        <div className="container">

            <Switch>
             <Route path="/" component={LastTweet} exact/>
             <Route path="/populer-gundem" component={LastTweet} exact/>
             <Route path="/24-saat" component={LastDay} exact/>
             <Route path="/son-saatler" component={LastHour} exact/>
             <Route path="/son-hafta" component={LastWeek} exact/>
              <Route path="/tum-zamanlar" component={AllDay} exact/>
             <Route path="/detay/:id" component={SingleTweet} exact/>
             <Route path="/profil/:id" component={UserTweets} exact/>
             
           </Switch>
        </div>
      </BrowserRouter>
    )
}