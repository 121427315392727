import React from 'react';

import axios from 'axios';
import ReactPlayer from 'react-player'
import { Link, } from "react-router-dom";
import './App.css';
import Tweet from "./Tweet";

export default class LastTweet extends React.Component {
    state = {
        lasttweet: [],
        lastsportweet: []
    }


    getTweets = () => {
        axios.get(`http://api.peypi.com/api/last`)
            .then(res => {
                const lasttweet = res.data;
                this.setState({ lasttweet });
            });
    };
    getSporTweets = () => {
        axios.get(`http://api.peypi.com/api/last?category=2`)
            .then(res => {
                const lastsportweet = res.data;
                this.setState({ lastsportweet });
            });
    };


    componentDidMount() {
        this.getTweets();
        this.getSporTweets();
        this.interval = setInterval(() => {
            this.getTweets();
        }, 60000);



    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }



    render() {

        return (



            <div className="row">


           <div className="col-sm-12">

         
           <h2 className="toptweet">Popüler Gündem</h2>
             <div className="card-columns">
             
        { this.state.lasttweet.map(tweet => 
                   <div className="card">
    <div className="card-body">
                    <Tweet tweet={tweet} key={tweet.id}/>
                     </div>
                    </div>
          )}
          </div>
           </div>

                   <div className="col-sm-12">
         
           <h2 className="toptweet">Popüler Spor Gündem</h2>
             <div className="card-columns">
        { this.state.lastsportweet.map(tweet => 
                   <div className="card">
    <div className="card-body">
                    <Tweet tweet={tweet} key={tweet.id}/>
                     </div>
                    </div>
          )}
          </div>
           </div>
      </div> 
     

        )
    }
}