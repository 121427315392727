import React from 'react';

import axios from 'axios';
import ReactPlayer from 'react-player'
import {Link,} from "react-router-dom";
import {Helmet} from "react-helmet";
import Tweet from "./Tweet";

import './App.css';


export default class AllDay extends React.Component {
  state = {
    lasttweet: []
  }

  getTweets = () => {
       axios.get(`http://api.peypi.com/api/popular`)
      .then(res => {
        const lasttweet = res.data;
        this.setState({ lasttweet });
      });
    };


  componentDidMount() {
          this.getTweets();
          this.interval = setInterval(() => {
      this.getTweets();
    }, 5000);

  }

  componentWillUnmount() {
   clearInterval(this.interval);
 }


  render() {
    return (
      <div>

                             <h2 className="toptweet">Tüm Zamanlar | Popüler Gündem</h2>
       <div className="card-columns">


        { this.state.lasttweet.map(tweet => 
  <div className="card">
    <div className="card-body">

                    <Tweet tweet={tweet} key={tweet.id}/>
</div>
 </div>

          )}
           
      </div>
      </div>
    )
  }
}
