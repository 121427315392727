import React from 'react';

import axios from 'axios';
import { Helmet } from "react-helmet";
import Tweet from "./Tweet";

import './App.css';


export default class UserTweets extends React.Component {
    
  constructor(props) {

        super(props);
        this.state = {
            lasttweet: [],
            user: [],
        };

    }


    getTweets = (id) => {
        axios.get(`http://api.peypi.com/api/profile/${id}`)
            .then(res => {
                const lasttweet = res.data;
                const user = lasttweet[0].profile;
                this.setState({ lasttweet });
                this.setState({ user });
            });
    };


    componentDidMount() {
                const id = this.props.match.params.id;

        this.getTweets(id);
        this.interval = setInterval(() => {
            this.getTweets();
        }, 600000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        return (
             <div>
 <Helmet>
                <title>{ `${ this.state.user.name }` } - @{ `${ this.state.user.screen_name }` }   | Sosyal Gündem</title>

            </Helmet>
           
                       <h2 className="toptweet">{this.state.user.name} (@{this.state.user.screen_name}) | Popüler Gündem</h2>
                       <p> {this.state.user.description}  </p>
 <div className="card-columns">


        { this.state.lasttweet.map(tweet => 
  <div className="card">
    <div className="card-body">

                    <Tweet tweet={tweet} key={tweet.id}/>
</div>
 </div>

          )}
           
      </div>
      </div>
        )
    }
}
