import React from 'react';

import { NavLink  } from 'react-router-dom';

export default class Header extends React.Component {
 

  render() {
    return (

    	<nav className="navbar navbar-expand-lg navbar-light bg-light">
    	<div className="container">
      <NavLink to="/" className="navbar-brand">Peypi - Sosyal Gündem</NavLink>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
     <li className="nav-item ">
          <NavLink to="/" className="nav-link">Popüler Gündem</NavLink>
      </li> 
     
       <li className="nav-item ">
          <NavLink to="/24-saat" className="nav-link">Son 24 Saat</NavLink>
      </li>  
       <li className="nav-item ">
          <NavLink to="/son-hafta" className="nav-link">Son 7 Gün</NavLink>
      </li>  
         <li className="nav-item ">
          <NavLink to="/tum-zamanlar" className="nav-link">Tüm Zamanlar</NavLink>
      </li>  

       
    </ul>
    </div>
  </div>
</nav>
    )
  }
}

