import React from 'react';
import { Link, } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/tr';
import NumberFormat from 'react-number-format';
import ReactPlayer from 'react-player'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class Tweet extends React.Component {
    render() {
        return (

            <React.Fragment key={this.props.tweet.id}>

          <div className="tweet">
          <p className="tweettext">    {(this.props.tweet.text)} </p>

                {this.props.tweet.image_url &&
                <p className="tweettext"> 

<LazyLoadImage effect="blur" src={this.props.tweet.image_url} className="img-fluid"/>
                  </p>

        }
        {this.props.tweet.image_url2 &&
                <p className="tweettext">  <LazyLoadImage effect="blur" src={this.props.tweet.image_url2} className="img-fluid"/> </p>

        }
        {this.props.tweet.image_url3 &&
                <p className="tweettext">  <LazyLoadImage effect="blur" src={this.props.tweet.image_url3} className="img-fluid"/> </p>

        }
         {this.props.tweet.image_url4 &&
                <p className="tweettext">  <LazyLoadImage effect="blur" src={this.props.tweet.image_url4} className="img-fluid"/> </p>

        }
             


     
{this.props.tweet.profile &&

                    <div className="tweetbottom"> <LazyLoadImage effect="blur" src={this.props.tweet.profile.profile_image_url} className="img-fluid"/>  <Link to={`/detay/${this.props.tweet.id}`}><Moment fromNow>{(this.props.tweet.tweet_date)}</Moment></Link> -  <Link to={`/profil/${this.props.tweet.username}`}>@{(this.props.tweet.username)} </Link> - <NumberFormat value={this.props.tweet.pp} displayType={'text'} thousandSeparator={true}  /> PP 
</div> 

            }
           

          </div>
        </React.Fragment>


        );
    }
}