import React from 'react';

import axios from 'axios';
import { Helmet } from "react-helmet";
import Tweet from "./Tweet";

import './App.css';


export default class LastDay extends React.Component {
    state = {
        lasttweet: []
    }



    getTweets = () => {
        axios.get(`http://api.peypi.com/api/lastday`)
            .then(res => {
                const lasttweet = res.data;
                this.setState({ lasttweet });
            });
    };


    componentDidMount() {
        this.getTweets();
        this.interval = setInterval(() => {
            this.getTweets();
        }, 600000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        return (
            <div>

                             <h2 className="toptweet">Son 24 Saat | Popüler Gündem</h2>
       <div className="card-columns">


        { this.state.lasttweet.map(tweet => 
  <div className="card">
    <div className="card-body">

                    <Tweet tweet={tweet} key={tweet.id}/>
</div>
 </div>

          )}
           
      </div>
      </div>
        )
    }
}